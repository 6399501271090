import { child, get, ref } from "firebase/database";
import { std } from "mathjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BLUE_ALLIANCE_API_HEADERS, URL } from "../../app.constants";
import { db } from "../../lib/firebase";
import LoaderComponent from "../LoaderComponent/LoaderComponent";


function UpcomingMatch() {
    const params = useParams();
    const compId = params.compId;
    const teamId = params.teamId;

    const [upcomingMatch, setUpcomingMatch] = useState({} as any);

    const [predictedScores, setPredictedScores] = useState({} as any);

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        getUpcomingMatch();
    }, [])

    const getUpcomingMatch = async () => {
        let upcomingMatch = {};
        const response = await fetch(`${URL}/team/${teamId}/event/${compId}/matches`, BLUE_ALLIANCE_API_HEADERS);
        const result = await response.json();
        const matchTeamIds = [...result[0].alliances.blue.team_keys, ...result[0].alliances.red.team_keys];
        for (let matchTeamId of matchTeamIds) {
            upcomingMatch[matchTeamId] = {
                team: matchTeamId,
                alliance: matchTeamIds.indexOf(matchTeamId) <= 2 ? "blue" : "red",
                totalPoints: [],
            };
            const teamForMatch = upcomingMatch[matchTeamId];

            const response2 = await get(child(ref(db), `${compId}`));
            const comp = response2.val();
            const matches = comp.matches;
            const teamStats = comp.teamStats;

            for (let matchId in matches) {
                for (let teamId in matches[matchId].teams) {
                    if (matchTeamId === teamId) {
                        const totalPoints = matches[matchId].teams[teamId].totalPoints;
                        if (totalPoints) {
                            teamForMatch.totalPoints.push(totalPoints);
                        }
                    }
                }
            }

            if (teamForMatch.totalPoints.length !== 0) {
                teamForMatch.averagePoints = teamStats[matchTeamId].totalPoints / teamForMatch.totalPoints.length;
                teamForMatch.standardDeviation = Number(std(teamForMatch.totalPoints).toFixed(3));
            }
        }
        setUpcomingMatch(upcomingMatch);
        scorePrediction(upcomingMatch);
        setIsLoaded(true);
    }

    const scorePrediction = (upcomingMatch) => {
        let blueScore = 0;
        let redScore = 0;
        let blueSTD = 0;
        let redSTD = 0;

        for (let teamId in upcomingMatch) {
            if (upcomingMatch[teamId].alliance === "blue") {
                blueScore += upcomingMatch[teamId].averagePoints;
                blueSTD += upcomingMatch[teamId].standardDeviation;
            } else {
                redScore += upcomingMatch[teamId].averagePoints;
                redSTD += upcomingMatch[teamId].standardDeviation;
            }
        }

        setPredictedScores({
            blueScore,
            blueSTD,
            redScore,
            redSTD
        });
    }

    const render = () => {
        if (isLoaded) {
            return (
                <div className="upcoming-match">
                    <h2>Upcoming Match</h2>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: "20%" }}>
                                    Team
                                </th>
                                <th style={{ width: "25%" }}>
                                    Avg Pts
                                </th>
                                <th style={{ width: "25%" }}>
                                    Std Dev
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(upcomingMatch).map(team => {
                                    return (
                                        <tr key={team} className={upcomingMatch[team].alliance === "blue" ? "blue-row" : "red-row"}>
                                            <td style={{ width: "20%" }}>
                                                {upcomingMatch[team].team}
                                            </td>
                                            <td style={{ width: "25%" }}>
                                                {upcomingMatch[team].averagePoints}
                                            </td>
                                            <td style={{ width: "25%" }}>
                                                {upcomingMatch[team].standardDeviation}
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    {/* <div className="predicted-scores">
                        <h2>Predicted Scores</h2>
                        <div className="teams">
                            <div className="team">
                                <h3>Blue</h3>
                                <p>
                                    {predictedScores.blueScore} <span>&#177;</span> {predictedScores.blueSTD}
                                </p>
                            </div>
                            <div className="team">
                                <h3>Red</h3>
                                <p>
                                    {predictedScores.redScore} <span>&#177;</span> {predictedScores.redSTD}
                                </p>
                            </div>
                        </div>
                    </div> */}
                </div>
            )
        } else {
            return (
                <LoaderComponent size="sm"/>
            )
        }
    }

    return (
        <>
            {render()}
        </>
    );
}

export default UpcomingMatch;