import { useState, useEffect } from "react";
import InputComponent from "../../components/InputComponent/InputComponent";
import { db } from "../../lib/firebase";
import { ref, onValue } from "firebase/database";
import { useNavigate, useParams } from "react-router-dom";
import "./EditMatchPage.scss";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import { DEFAULT_MATCH } from "../../app.constants";

function MatchPage() {
  let [match, setMatch] = useState(DEFAULT_MATCH as any);

  const [teamStats, setTeamStats] = useState(DEFAULT_MATCH as any);

  const [isLoaded, setIsLoaded] = useState(false);

  const params = useParams();

  const compId = params.matchId.split("_")[0];
  const matchId = params.matchId.split("_")[1];
  const teamId = params.teamId;
  const matchDbPath = `${compId}/matches/${matchId}/teams/${teamId}`;
  const teamDbPath = `${compId}/teamStats/${teamId}`;

  const navigate = useNavigate();

  useEffect(() => {
    getMatch();
    getTeamStats();
  }, []);

  const getMatch = () => {
    const matchRef = ref(db, matchDbPath);
    onValue(matchRef, (snapshot) => {
      if (snapshot.val()) {
        setMatch(snapshot.val());
      }
    });
  }

  const getTeamStats = () => {
    const teamRef = ref(db, teamDbPath);
    onValue(teamRef, (snapshot) => {
      if (snapshot.val()) {
        setTeamStats(snapshot.val());
      }
      setIsLoaded(true);
    })
  }

  const render = () => {
    if (isLoaded) {
      return (
        <section className="EditMatchPage">
          <div className="header">
            <span onClick={() => navigate(-1)} className="arrow">
              &larr;
            </span>
            <h1>
              {compId} - {matchId} - {teamId}
            </h1>
            <span></span>
          </div>
          <div className="form">
            <div className="row">
              <div className="column-1">
                <h2>Auto</h2>
                <h3>Upper</h3>
                <InputComponent type="special-number" label="Shots Made" match={match} teamStats={teamStats} property="auto.upper.made" />
                <InputComponent type="special-number" label="Shots Missed" match={match} teamStats={teamStats} property="auto.upper.missed" />
                <h3>Lower</h3>
                <InputComponent type="special-number" label="Shots Made" match={match} teamStats={teamStats} property="auto.lower.made" />
                <InputComponent type="special-number" label="Shots Missed" match={match} teamStats={teamStats} property="auto.lower.missed" />
              </div>
              <div className="column-2">
                <h2>Teleop</h2>
                <h3>Upper</h3>
                <InputComponent type="special-number" label="Shots Made" match={match} teamStats={teamStats} property="teleop.upper.made" />
                <InputComponent type="special-number" label="Shots Missed" match={match} teamStats={teamStats} property="teleop.upper.missed" />
                <h3>Lower</h3>
                <InputComponent type="special-number" label="Shots Made" match={match} teamStats={teamStats} property="teleop.lower.made" />
                <InputComponent type="special-number" label="Shots Missed" match={match} teamStats={teamStats} property="teleop.lower.missed" />
              </div>
            </div>
            <div className="horizontal-line"></div>
            <div className="input-row">
              <InputComponent type="dropdown" label="Hangar Points" match={match} teamStats={teamStats} property="hangarPoints" styles={{ margin: 0, marginRight: "2.5rem" }} />
              <InputComponent type="text" label="Climb Start Time" match={match} property="climbStartTime" />
            </div>
            <div className="input-row">
              <InputComponent type="dropdown" label="Exited Tarmac" match={match} teamStats={teamStats} property="exitedTarmac" styles={{ margin: 0, marginRight: "2.5rem" }} />
              <InputComponent type="text" label="Penalty Points" match={match} teamStats={teamStats} property="penaltyPoints" />
            </div>
            <div className="notes">
              <InputComponent type="textarea" label="Extra Notes" match={match} property="extraNotes" />
            </div>
          </div>
        </section>
      )
    } else {
      return (
        <LoaderComponent size="md" />
      )
    }
  }

  return (
    <>
      {render()}
    </>
  );
}

export default MatchPage;
