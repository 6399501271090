import { Match } from "./interfaces/match.interface";

export const API_KEY = 'rsiteCWDMD1Hf5BuIySfMqady2bdCBdGUyuLjHMQct5HSv94gkVIg1DTyWuIWhLK';
export const URL = 'https://www.thebluealliance.com/api/v3';
export const TEAM_KEY = 'frc6897';
export const YEAR = '2022';
export const BLUE_ALLIANCE_API_HEADERS = {
    headers: {
        'X-TBA-Auth-Key': API_KEY
    }
};
export const DEFAULT_MATCH: Match = {
    auto: {
        upper: {
            missed: 0,
            made: 0,
        },
        lower: {
            missed: 0,
            made: 0,
        },
    },
    teleop: {
        upper: {
            missed: 0,
            made: 0,
        },
        lower: {
            missed: 0,
            made: 0,
        },
    },
    hangarPoints: "",
    exitedTarmac: "",
    climbStartTime: "",
    penaltyPoints: 0,
    totalPoints: 0
};

export const getAccuracy = (made: number, missed: number, percentage?: boolean) => {
    const value = Math.round((made / (made + missed)) * 100);
    if (percentage) {
        return `${value}%`;
    } else {
        return value;
    }
};
