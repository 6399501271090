import './App.scss'
import CompPage from './pages/CompPage/CompPage';
import EditMatchPage from './pages/EditMatchPage/EditMatchPage';
import HomePage from './pages/HomePage/HomePage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DriverDashboardPage from './pages/DriverDashboardPage/DriverDashboardPage';
import MatchesPage from './pages/MatchesPage/MatchesPage';


function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:compId/teams" element={<CompPage defaultTabIndex={0}/>} />
          <Route path="/:compId/matches" element={<CompPage defaultTabIndex={1} />} />
          <Route path="/:compId/driver-dashboard/:teamId" element={<DriverDashboardPage />} />
          <Route path="/:compId/:matchId/:teamId" element={<EditMatchPage />} />
        </Routes>
      </div>
    </BrowserRouter>

  )

}

export default App;
