import "./InputComponent.scss";
import { db } from '../../lib/firebase';
import { ref, set } from "firebase/database";
import { Match } from "../../interfaces/match.interface";
import lodash from "lodash"
import { useParams } from "react-router-dom";

function InputComponent(props: { type: string, label: string, match: Match, teamStats?: any, property?: string, styles?: any, readonly?: boolean, value?: any }) {
    const params = useParams();

    const compId = params.matchId.split("_")[0];
    const matchId = params.matchId.split("_")[1];
    const teamId = params.teamId;

    const matchDbPath = `${compId}/matches/${matchId}/teams/${teamId}`;
    const teamDbPath = `${compId}/teamStats/${teamId}`;

    const rungToPoints = {
        "None": 0,
        "Low": 4,
        "Mid": 6,
        "High": 10,
        "Traversal": 15
    };

    const pointsToRung = {
        "0": "None",
        "4": "Low",
        "6": "Mid",
        "10": "High",
        "15": "Traversal"
    };

    const shotTypeToPoints = {
        "autoupper": 4,
        "autolower": 2,
        "teleopupper": 2,
        "teleoplower": 1
    };

    function onChangeValue(event: any, type: string) {
        let value;
        if (type === "number") {
            value = Number(event.target.value);
        } else if (type === "hangarPoints") {
            value = rungToPoints[event.target.value];
        } else {
            value = event.target.value;
        }

        const oldValue = lodash.get(props.match, props.property);
        if (!["penaltyPoints", "extraNotes", "climbStartTime", "hangarPoints", "exitedTarmac"].includes(props.property)) {
            value = shotTypeToPoints[`${props.property.split(".")[0]}${props.property.split(".")[1]}`];
            if (oldValue < value) {
                lodash.set(props.teamStats, props.property, lodash.get(props.teamStats, props.property) + (value - oldValue));
                if (props.property.includes("made")) {
                    lodash.set(props.teamStats, "totalPoints", lodash.get(props.teamStats, "totalPoints") + (value - oldValue));
                    lodash.set(props.match, "totalPoints", lodash.get(props.match, "totalPoints") + (value - oldValue));
                }
            } else {
                lodash.set(props.teamStats, props.property, lodash.get(props.teamStats, props.property) - (oldValue - value));
                if (props.property.includes("made")) {
                    lodash.set(props.teamStats, "totalPoints", lodash.get(props.teamStats, "totalPoints") - (oldValue - value));
                    lodash.set(props.match, "totalPoints", lodash.get(props.match, "totalPoints") - (oldValue - value));
                }
            }
            updateStats();
        } else if (props.property.includes("hangarPoints")) {
            if (oldValue < value) {
                lodash.set(props.teamStats, "totalPoints", lodash.get(props.teamStats, "totalPoints") + (value - oldValue));
                lodash.set(props.teamStats, "hangarPoints", lodash.get(props.teamStats, "hangarPoints") + (value - oldValue));
                lodash.set(props.match, "totalPoints", lodash.get(props.match, "totalPoints") + (value - oldValue));
            } else {
                lodash.set(props.teamStats, "totalPoints", lodash.get(props.teamStats, "totalPoints") - (oldValue - value));
                lodash.set(props.teamStats, "hangarPoints", lodash.get(props.teamStats, "hangarPoints") - (oldValue - value));
                lodash.set(props.match, "totalPoints", lodash.get(props.match, "totalPoints") - (oldValue - value));
            }
        } else if (props.property.includes("exitedTarmac")) {
            if (value === "Yes") {
                lodash.set(props.teamStats, "totalPoints", lodash.get(props.teamStats, "totalPoints") + (2));
                lodash.set(props.match, "totalPoints", lodash.get(props.match, "totalPoints") + (2));
            } else if (oldValue !== "") {
                lodash.set(props.teamStats, "totalPoints", lodash.get(props.teamStats, "totalPoints") - (2));
                lodash.set(props.match, "totalPoints", lodash.get(props.match, "totalPoints") - (2));
            }
        }

        lodash.set(props.match, props.property, value);
        updateStats();
        updateMatch();
    }

    function onIncrementValue(property: string) {
        lodash.set(props.match, property, lodash.get(props.match, property) + 1);
        lodash.set(props.teamStats, property, lodash.get(props.teamStats, property) + 1);
        const points = shotTypeToPoints[`${property.split(".")[0]}${property.split(".")[1]}`];
        if (property.includes("made")) {
            lodash.set(props.match, "totalPoints", lodash.get(props.match, "totalPoints") + points);
            lodash.set(props.teamStats, "totalPoints", lodash.get(props.teamStats, "totalPoints") + points);
        }
        updateStats();
        updateMatch();
    }

    function onDecrementValue(property: string) {
        lodash.set(props.match, property, lodash.get(props.match, property) - 1);
        lodash.set(props.teamStats, property, lodash.get(props.teamStats, property) - 1);
        const points = shotTypeToPoints[`${property.split(".")[0]}${property.split(".")[1]}`];
        if (property.includes("made")) {
            lodash.set(props.teamStats, "totalPoints", lodash.get(props.teamStats, "totalPoints") - points);
            lodash.set(props.match, "totalPoints", lodash.get(props.match, "totalPoints") - points);
        }
        updateStats();
        updateMatch();
    }

    function updateMatch() {
        set(ref(db, matchDbPath), props.match);
    }

    function updateStats() {
        console.log(props.teamStats, teamDbPath)
        set(ref(db, teamDbPath), props.teamStats);
    }

    if (props.type === "special-number") {
        return (
            <section style={props.styles} className="InputComponent">
                <label>{props.label}</label>
                <div className="input-buttons">
                    <input type="text" value={lodash.get(props.match, props.property)} onChange={(e) => { onChangeValue(e, "number"); }} readOnly={true} />
                    <div className="buttons">
                        <button onClick={() => { onIncrementValue(props.property); }} className="button square-button purple-button">
                            +
                        </button>
                        <button onClick={() => { onDecrementValue(props.property); }} className="button square-button purple-button">
                            -
                        </button>
                    </div>
                </div>
            </section>
        )
    } else if (props.type === "textarea") {
        return (
            <section style={props.styles} className="InputComponent">
                <label>{props.label}</label>
                <textarea value={lodash.get(props.match, props.property)} onChange={(e) => { onChangeValue(e, props.type); }} readOnly={props.readonly} />
            </section>
        )
    } else if (props.type === "dropdown") {
        return (
            <section style={props.styles} className="InputComponent">
                <label htmlFor="dropdown">{props.label}</label>
                {
                    props.property === "hangarPoints"
                        ? (
                            <div>
                                <select value={pointsToRung[props.match[props.property]]} onChange={(e) => { onChangeValue(e, "hangarPoints") }} id="dropdown" placeholder="Select">
                                    <option value="None">None</option>
                                    <option value="Low">Low</option>
                                    <option value="Mid">Mid</option>
                                    <option value="High">High</option>
                                    <option value="Traversal">Traversal</option>
                                </select>

                            </div>
                        )
                        : (
                            <div>
                                <select value={lodash.get(props.match, props.property)} onChange={(e) => { onChangeValue(e, "dropdown") }} id="dropdown" placeholder="Select">
                                    <option value="">Select your option</option>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </div>
                        )
                }
            </section>
        )
    } else {
        return (
            <section style={props.styles} className="InputComponent">
                <label>{props.label}</label>
                <input type={props.type} value={lodash.get(props.match, props.property)} onChange={(e) => { onChangeValue(e, props.type); }} readOnly={props.readonly} />
            </section>
        )
    }
}

export default InputComponent;
