import { Link } from "react-router-dom";
import "./TeamsPage.scss";

function TeamsPage(props: { teams, compId }) {

    return (
        <div>
            <p>
                {" "}
                Found <b>{props.teams.length}</b> teams!{" "}
            </p>
            {
                props.teams.map((key) => (
                    <Link key={key.team_number} to={`/${props.compId}/driver-dashboard/frc${key.team_number}`}>
                        <div className="card">
                            <p>{"Team " + key.team_number + ": " + key.nickname} </p>
                        </div>
                    </Link>
                ))
            }
        </div>
    )
}

export default TeamsPage;