import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { URL, TEAM_KEY, YEAR, BLUE_ALLIANCE_API_HEADERS } from "../../app.constants";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import './HomePage.scss'

function HomePage() {
    const [events, setEvents] = useState([]);

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        getEvents();
    }, [])

    const getEvents = () => {
        fetch(`${URL}/team/${TEAM_KEY}/events/${YEAR}`, BLUE_ALLIANCE_API_HEADERS)
            .then(response => response.json())
            .then(result => {
                setEvents(result);
                setIsLoaded(true);
            })
    }

    function refreshPage() {
        window.location.reload();
    }

    const render = () => {
        if (isLoaded) {
            return (
                <section className="HomePage">
                    <h1>Apollo</h1>
                    <p>
                        Found&nbsp;
                        <b>{events.length}</b>
                        &nbsp;competitions for&nbsp;
                        <b>{6897}</b>
                        &nbsp;in&nbsp;
                        <b>{YEAR}</b> :
                    </p>
                    {
                        events.map(data => (
                            <Link to={`${data.key}/teams`}>
                                <div className="card">
                                    <p>
                                        {data.name + ' (' + data.event_type_string + ") "}
                                    </p>
                                </div>
                            </Link>
                        ))
                    }
                    <div className="buttons">
                        <button className="button round-button purple-button" onClick={() => refreshPage()}>Refresh</button>
                    </div>
                </section>
            )
        } else {
            return (
                <LoaderComponent size="md" />
            )
        }
    }

    return (
        <>
            {render()}
        </>
    );
}

export default HomePage