import { useEffect, useState } from "react"
import Chart from "react-google-charts"
import { getAccuracy } from "../../app.constants";
import { Match } from "../../interfaces/match.interface";

function AccuracyPerMatchGraph(props: { matches: Match[] }) {

    const [chartData, setChartData] = useState([]);

    const chartOptions = {
        title: "Accuracy Per Match",
        legend: { position: "bottom" },
    };

    useEffect(() => {
        createChart(props.matches);
    }, [props.matches])

    const createChart = (matches: Match[]) => {
        let chartData = [];

        chartData = [
            ["Match", "Auto Upper", "Auto Lower", "Teleop Upper", "Teleop Lower"]
        ];

        for (let match of matches) {
            chartData.push(
                [
                    match.id,
                    getAccuracy(match.auto.upper.made, match.auto.upper.missed),
                    getAccuracy(match.auto.lower.made, match.auto.lower.missed),
                    getAccuracy(match.teleop.upper.made, match.teleop.upper.missed),
                    getAccuracy(match.teleop.lower.made, match.teleop.lower.missed)
                ]
            );
        }

        setChartData(chartData);
    }

    return (
        <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={chartData}
            options={chartOptions}
        />
    )
}

export default AccuracyPerMatchGraph