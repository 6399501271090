import { useState, useEffect } from "react";
import './MatchComponent.scss'

function MatchComponent(props: { match: any }) {
    const [matchName, setMatchName] = useState("");
    const [blueTeams, setBlueTeams] = useState([]);
    const [redTeams, setRedTeams] = useState([]);

    useEffect(() => {
        getMatch();
    }, [])


    async function getMatch() {
        setMatchName(props.match.key.split("_")[1]);
        setRedTeams(props.match.alliances.red.team_keys)
        setBlueTeams(props.match.alliances.blue.team_keys)
    }

    return (
        <>
            <section className="MatchPage">
                <h2>{matchName}</h2>
                <div className="teams">
                    <div className="blue-alliance">
                        {
                            blueTeams.map(team => (
                                <a key={team} href={`/match/${props.match.key}/${team}/`}>
                                    <div className="card">
                                        <p>{team}</p>
                                    </div>
                                </a>

                            ))
                        }
                    </div>
                    <div className="red-alliance">
                        {
                            redTeams.map(team => (
                                <a key={team} href={`/match/${props.match.key}/${team}/`}>
                                    <div className="card">
                                        <p>{team}</p>
                                    </div>

                                </a>
                            ))
                        }
                    </div>
                </div>
            </section>

        </>
    )
}

export default MatchComponent;
