import "./CompPage.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate, useParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { BLUE_ALLIANCE_API_HEADERS, URL } from "../../app.constants";
import { useEffect, useState } from "react";
import TeamsPage from "../TeamsPage/TeamsPage";
import MatchesPage from "../MatchesPage/MatchesPage";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";

function CompPage(props: { defaultTabIndex: number }) {
  const navigate = useNavigate();

  const params = useParams();
  const compId = params.compId;

  const [teams, setTeams] = useState([]);
  
  const [qualMatches, setQualMatches] = useState([]);
  const [qfMatches, setQfMatches] = useState([]);
  const [sfMatches, setSfMatches] = useState([]);
  const [fMatches, setFMatches] = useState([]);

  const [tabIndex, setTabIndex] = useState(0);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getTeams();
    getMatches();
    setTabIndex(props.defaultTabIndex);
  }, [])

  const getTeams = () => {
    fetch(`${URL}/event/${compId}/teams`, BLUE_ALLIANCE_API_HEADERS)
      .then(response => response.json())
      .then(result => {
        setTeams(result.sort((a, b) => (a.team_number > b.team_number ? 1 : -1)));
        setIsLoaded(true);
      })
  }

  const getMatches = () => {
    fetch(`${URL}/event/${compId}/matches`, BLUE_ALLIANCE_API_HEADERS)
      .then(response => response.json())
      .then(result => {
        result = result.sort((a, b) => (a.match_number > b.match_number ? 1 : -1));
        setQualMatches(result.filter(match => match.comp_level === "qm"));
        setQfMatches(result.filter(match => match.comp_level === "qf"));
        setSfMatches(result.filter(match => match.comp_level === "sf"));
        setFMatches(result.filter(match => match.comp_level === "f"));
      })
  }
  
  const render = () => {  
    if (isLoaded) {
      return (
        <section className="CompPage">
          <Tabs selectedIndex={tabIndex} onSelect={(index) => {
            if (index === 0) {
              navigate(`/${compId}/teams`, { replace: true });
            } else {
              navigate(`/${compId}/matches`, { replace: true });
            }
            setTabIndex(index);
          }}>
            <div className="header">
              <span onClick={() => navigate(-1)} className="arrow">
                &larr;
              </span>
              <h1>{compId}</h1>
              <span></span>
            </div>
            <TabList>
              <Tab>Teams</Tab>
              <Tab>Matches</Tab>
            </TabList>
            <TabPanel>
              <TeamsPage compId={compId} teams={teams} />
            </TabPanel>
            <TabPanel>
              <MatchesPage qualMatches={qualMatches} qfMatches={qfMatches} sfMatches={sfMatches} fMatches={fMatches} />
            </TabPanel>
          </Tabs>
        </section>
      )
    } else {
      return (
        <LoaderComponent size="md" />
      )
    }
  }

  return (
    <>
      {render()}
    </>
  );
}

export default CompPage;
