import { useEffect, useState } from "react";
import { TEAM_KEY } from "../../app.constants";
import MatchComponent from "../../components/MatchComponent/MatchComponent";
import SearchComponent from "../../components/SearchComponent/SearchComponent";
import "./MatchesPage.scss";

function MatchesPage(props: { qualMatches, qfMatches, sfMatches, fMatches }) {

    useEffect(() => {
    }, [])

    const [targetTeam, setTargetTeam] = useState("");

    const isOurTeamGame = (match: any) => {
        return isTargetTeamGame(match, TEAM_KEY);
    }

    const isTargetTeamGame = (match: any, targetTeam: string) => {
        const teams = [...match.alliances.blue.team_keys, ...match.alliances.red.team_keys];
        if (teams.filter(teamId => teamId.includes(targetTeam)).length !== 0) {
            return true;
        } else {
            return false;
        }
    }

    const generateMatchComponent = (key) => {
        return (
            <div key={key.key} className={`card ${isOurTeamGame(key) ? 'our-team' : ''} ${isTargetTeamGame(key, targetTeam) ? '' : 'hidden'}`}>
                <MatchComponent match={key} />
            </div>
        )
    }

    return (

        <div className="matches">

            <SearchComponent name={targetTeam} changeHandler={event => setTargetTeam(event.target.value)} />

            <p>
                {" "}
                Found <b>
                    {props.qualMatches.length}
                </b> matches!{" "}
            </p>

            <h3>Qualification Matches</h3>
            {props.qualMatches.map((key) => (generateMatchComponent(key)))}

            <h3>Playoff Matches</h3>
            {props.qfMatches.map((key) => (generateMatchComponent(key)))}

            {props.sfMatches.map((key) => generateMatchComponent(key))}

            {props.fMatches.map((key) => (generateMatchComponent(key)))}
        </div>
    )
}

export default MatchesPage;