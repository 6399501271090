import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDmlWPYb3lZ5Vet0nQTv2KZtLrCW7xpwso",
    authDomain: "apollo-scouting.firebaseapp.com",
    databaseURL: "https://apollo-scouting-default-rtdb.firebaseio.com",
    projectId: "apollo-scouting",
    storageBucket: "apollo-scouting.appspot.com",
    messagingSenderId: "815655165081",
    appId: "1:815655165081:web:59dc2ee45e4c473f5c2c08"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getDatabase(app);