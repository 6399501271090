import { useEffect, useState } from "react";
import { getAccuracy } from "../../app.constants";
import { Match } from "../../interfaces/match.interface";

function TeamStatistics(props: { matches: Match[], teamStats: any }) {
    const [averageAutoUpper, setAverageAutoUpper] = useState(0);
    const [averageAutoLower, setAverageAutoLower] = useState(0);
    const [averageTeleopUpper, setAverageTeleopUpper] = useState(0);
    const [averageTeleopLower, setAverageTeleopLower] = useState(0);
    const [averageHangarPoints, setAverageHangarPoints] = useState(0);
    const [averageTotalPoints, setAverageTotalPoints] = useState(0);

    useEffect(() => {
        createStatistics();
    }, [props.teamStats])

    const createStatistics = () => {
        console.log(props.teamStats.totalPoints, props.matches)
        setAverageAutoUpper(Math.round(props.teamStats.auto.upper.made / props.matches.length) * 4);
        setAverageAutoLower(Math.round(props.teamStats.auto.lower.made / props.matches.length) * 2);
        setAverageTeleopUpper(Math.round(props.teamStats.teleop.upper.made / props.matches.length) * 2);
        setAverageTeleopLower(Math.round(props.teamStats.teleop.lower.made / props.matches.length) * 1);
        setAverageHangarPoints(Math.round(props.teamStats.hangarPoints / props.matches.length));
        setAverageTotalPoints(Math.round(props.teamStats.totalPoints / props.matches.length));
    }

    return (
        <div className="form">
            <div className="row">
                <div className="column-1">
                    <h2>Auto</h2>
                    <h3>Upper</h3>
                    <div className="statistic" style={{ marginBottom: "1.5rem" }}>
                        <p>Accuracy</p>
                        <div className="input-box">
                            {getAccuracy(props.teamStats.auto.upper.made, props.teamStats.auto.upper.missed, true)}
                        </div>
                    </div>
                    <div className="statistic">
                        <p>Average Points</p>
                        <div className="input-box">
                            {averageAutoUpper}
                        </div>
                    </div>
                    <h3>Lower</h3>
                    <div className="statistic" style={{ marginBottom: "1.5rem" }}>
                        <p>Accuracy</p>
                        <div className="input-box">
                            {getAccuracy(props.teamStats.auto.lower.made, props.teamStats.auto.lower.missed, true)}
                        </div>
                    </div>
                    <div className="statistic">
                        <p>Average Points</p>
                        <div className="input-box">
                            {averageAutoLower}
                        </div>
                    </div>
                </div>
                <div className="column-2">
                    <h2>Teleop</h2>
                    <h3>Upper</h3>
                    <div className="statistic" style={{ marginBottom: "1.5rem" }}>
                        <p>Accuracy</p>
                        <div className="input-box">
                            {getAccuracy(props.teamStats.teleop.upper.made, props.teamStats.teleop.upper.missed, true)}
                        </div>
                    </div>
                    <div className="statistic">
                        <p>Average Points</p>
                        <div className="input-box">
                            {averageTeleopUpper}
                        </div>
                    </div>
                    <h3>Lower</h3>
                    <div className="statistic" style={{ marginBottom: "1.5rem" }}>
                        <p>Accuracy</p>
                        <div className="input-box">
                            {getAccuracy(props.teamStats.teleop.lower.made, props.teamStats.teleop.lower.missed, true)}
                        </div>
                    </div>
                    <div className="statistic">
                        <p>Average Points</p>
                        <div className="input-box">
                            {averageTeleopLower}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="column-1">
                    <h2>Hangar</h2>
                    <div className="statistic" style={{ marginBottom: "1.5rem" }}>
                        <p>Average Points</p>
                        <div className="input-box">
                            {averageHangarPoints}
                        </div>
                    </div>
                </div>
                <div className="column-2">
                    <h2>Total</h2>
                    <div className="statistic" style={{ marginBottom: "1.5rem" }}>
                        <p>Average Points</p>
                        <div className="input-box">
                            {averageTotalPoints}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamStatistics;