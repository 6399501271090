import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BLUE_ALLIANCE_API_HEADERS, URL } from "../../app.constants";
import { child, get, onValue, ref, set } from "firebase/database";
import { db } from "../../lib/firebase";
import { Match } from "../../interfaces/match.interface";
import AccuracyPerMatchGraph from "../../components/DriverDashboard/AccuracyPerMatchGraph";
import TeamStatistics from "../../components/DriverDashboard/TeamStatistics";
import UpcomingMatch from "../../components/DriverDashboard/UpcomingMatch";
import "./DriverDashboardPage.scss";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";

function DriverDashboardPage() {
    const params = useParams();
    const compId = params.compId;
    const teamId = params.teamId;
    const teamDbPath = `${compId}/teamStats/${teamId}`;

    const navigate = useNavigate();

    const [teamStats, setTeamStats] = useState(null);

    const [matches, setMatches] = useState([]);

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        getMatchIds();
    }, [])

    const getMatchIds = () => {
        fetch(`${URL}/team/${teamId}/event/${compId}/matches`, BLUE_ALLIANCE_API_HEADERS)
            .then(response => response.json())
            .then(result => {
                result = result.map(match => match.key.split("_")[1]);
                getMatches(result);
            })
    }

    const getMatches = async (matchIds: string) => {
        let matches = [] as Match[];
        for (let matchId of matchIds) {
            const response = await get(child(ref(db), `${compId}/matches/${matchId}/teams/${teamId}`));
            const data = response.val();
            if (data) {
                matches.push({ id: matchId, ...data });
            }
        }
        setMatches(matches);
        getTeamStats();
    }

    const getTeamStats = () => {
        const teamRef = ref(db, teamDbPath);
        onValue(teamRef, (snapshot) => {
            console.log(snapshot.val())
            if (snapshot.val()) {
                setTeamStats(snapshot.val());
                setIsLoaded(true);
            }
        })
    }

    const render = () => {
        if (isLoaded) {
            return (
                <section className="DriverDashboardPage">
                    <div className="header">
                        <span onClick={() => navigate(-1)} className="arrow">
                            &larr;
                        </span>
                        <h1>
                            {compId} - Dashboard - {teamId}
                        </h1>
                        <span></span>
                    </div>
                    <div>
                        {
                            teamStats
                                ?
                                <div>
                                    <UpcomingMatch />
                                    <TeamStatistics matches={matches} teamStats={teamStats} />
                                    <AccuracyPerMatchGraph matches={matches} />
                                </div>
                                :
                                <h2 style={{ textAlign: "center" }}>
                                    There is no data for the team to render any statistics, sorry.
                                </h2>
                        }
                    </div>
                </section>
            )
        } else {
            return (
                <LoaderComponent size="md" />
            )
        }
    }

    return (
        <>
            {render()}
        </>
    );
}

export default DriverDashboardPage;
