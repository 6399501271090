import './SearchComponent.scss'

function SearchComponent(props: { name, changeHandler }) {


    return (
        <div className="search-filter">
            <input
                onChange={props.changeHandler}
                type="text"
                id="search"
                name="search"
                value={props.name}
                placeholder="Team #"
            />
        </div>
    )
}

export default SearchComponent;